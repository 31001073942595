<template>
  <router-view v-if="authRoute" />
  <Layout v-else>
    <template #main>
      <router-view />
    </template>
  </Layout>
</template>
<script>
import Layout from './components/Layout.vue';
export default {
  components: {
    Layout,
  },
  data() {
    return {
      authRoute: true,
      name:true
    }
  },
  watch: {
    $route: {
      handler: function (path) {
        this.authRoute = (path.name === 'Login' || path.name === 'PortfolioPage' || path.name === '404')
      }
    }
  },

}
</script>

<style>
#portfolio{
  background-color:#5f6ee0 ;
}
.modal-content {
  animation-name: animatetop;
  animation-duration:1000ms
}

/* Add Animation */
@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}
@import "@vueform/multiselect/themes/default.css";
</style>




