export default function auth({ next }) {
    // let isLoggedIn = false ;// Can be calculated through store
    // let isLoggedIn = store.getters['sessionData/isLoggedIn']
    let isLoggedIn = localStorage.getItem("token");
    
    if (!isLoggedIn) {
        return next({
            name: 'Login'
        })
    }

    return next();
}