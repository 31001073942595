<template>
<!-- <div v-if="isOpen"> -->
<section class="z-20 h-screen w-screen bg-gray-500 fixed top-0 opacity-50" @click="$emit('close-modal')"></section>
<div class="absolute inset-0">
    <div class="flex mt-[3.75rem]">
        <div class="z-30 m-auto bg-white border rounded p-2 shadow md:w-1/3 w-4/5">
            <div class="border p-3">
                <h1 class="text-2xl text-center font-medium">Change Password</h1>
                <form @click="prevent" class="my-2 p-3">
                    <div class="my-2">
                        <label>Old Password</label>
                        <input v-model="old_password" class="rounded w-full p-2 border" type="password" placeholder="Old Password">
                    </div>
                    <div class="my-2">
                        <label>password</label>
                        <input v-model="password" class="rounded w-full p-2 border" type="password" placeholder="password">
                    </div>
                    <div class="my-3">
                        <label>password confirmation</label>
                        <input v-model="password_confirmation" class="rounded w-full p-2 border" type="password" placeholder="password confirmation">
                    </div>
                    <div class=" flex justify-end gap-4">
                        <button type="button" class="px-3 py-2 text-white bg-indigo-500" @click="updatepassword()">Save</button>
                        <button @click="$emit('close-modal')" type="button" class="px-3 py-2 bg-black text-white">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- </div> -->
</template>
<script>
import changepasswordApi from '@/utils/changepasswordApi';
export default {
    props: {
        showmodal2: Boolean,
    },
    data() {
        return {
            old_password: '',
            password: '',
            password_confirmation: '',
        }
    },
    methods: {
      async updatepassword() {
            let params = {
                old_password: this.old_password,
                password: this.password,
                password_confirmation: this.password_confirmation,
            }

           await changepasswordApi.post(params)
                .then((res) => {
                    this.$swal({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                }).catch(error => {
                    console.log(error);
                })
            this.$emit('close-modal');
        },

    },
}
</script>
