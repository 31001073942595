
const setAuthHeader = () => {

    let token = localStorage.getItem('token');
    if (token) {
      return {
        'Authorization': "Bearer " + token,
        'Accept': 'application/json',
        'ngrok-skip-browser-warning': 'TRUE',
        "Content-Type": "multipart/form-data"
      };
  
    } else {
      return {};
    }
  };
  
  
  
  export default setAuthHeader;