import axios from 'axios';
import authHeader from './setAuthHeader';
let API_URL = process.env.VUE_APP_ROOT_API

class changepassword {

  post(data){
    return axios.post(API_URL + 'admin/change-password',data,{ headers: authHeader() });
  }
  
  }
  
  export default new changepassword();