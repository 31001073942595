<template>
<div>
    <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog as="div" class="relative z-40 md:hidden" @close="sidebarOpen = false">
            <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </TransitionChild>

            <div class="fixed inset-0 z-40 flex">
                <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
                    <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-slate-100 pt-3 pb-4">
                        <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                            <div class="absolute top-0 right-0 -mr-12 pt-2">
                                <button type="button" class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                                    <span class="sr-only">Close sidebar</span>
                                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                                </button>
                            </div>
                        </TransitionChild>
                        
                        <div class="flex gap-3 h-16 flex-shrink-0 items-center bg-slate-50 px-3 shadow">
                            <img class="h-8 w-8 rounded-full" src="http://octalinfotech.com/img/octal-logo.png" alt="Your Company" />
                            <h1 class="text-black text-lg font-bold space-x-3">OCTAL INFOTECH</h1>
                        </div>
                        <div class="flex flex-1 flex-col overflow-y-auto">
                            <nav class="flex-1 space-y-1 px-2 py-4">
                                <template v-for="item in navigationItems" :key="item.name">
                                    <router-link v-if="item.name" :to="item.href ? item.href : '/'" :class="[item.current ? 'bg-slate-50 text-white' : 'text-black  hover:bg-slate-300 hover:text-black focus:bg-slate-300 text-decoration-line:none active:bg-slate-300 ', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']" style="text-decoration: none;">
                                        <component :is="item.icon" :class="[item.current ? 'text-gray-300' : 'text-black', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                                        {{ item.name }}
                                    </router-link>
                                </template>
                            </nav>
                        </div>
                    </DialogPanel>
                </TransitionChild>
                <div class="w-14 flex-shrink-0" aria-hidden="true">
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex min-h-0 flex-1 flex-col bg-slate-100">
            <div class="flex h-16 flex-shrink-0 gap-3 items-center bg-slate-50 shadow-sm  px-4">
                <img class="h-8 w-8 rounded-full" src="@/assets/logooctal.png" alt="Your Company" />
                <h1 class="text-black text-lg  font-bold space-x-3">Octal Infotech</h1>
            </div>

            <div class="flex flex-1 flex-col overflow-y-auto shadow">
                <nav class="flex-1 space-y-1 px-2 py-4">
                    <template v-for="item in navigationItems" :key="item.name">
                        <router-link v-if="item.name" :to="item.href ? item.href : '/'" :class="[item.current ? 'bg-slate-50 text-white' : 'text-black  hover:bg-slate-300 hover:text-black focus:bg-slate-300 text-decoration-line:none active:bg-slate-300 ', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']" style="text-decoration: none;">
                            <component :is="item.icon" :class="[item.current ? 'text-gray-300' : 'text-black', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                            {{ item.name }}
                        </router-link>
                    </template>
                </nav>
            </div>
        </div>
    </div>
    <div class="flex flex-col md:pl-64">
        <div class="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-slate-50 shadow-sm">
            <button type="button" class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden" @click="sidebarOpen = true">
                <span class="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true" />
            </button>
            <div class="flex items-center font-semibold bg-slate-50">
                        <span class="pl-3 text-xl">{{ $route.meta.title }}</span>
             </div>
            <div class="flex flex-1 justify-end px-4" v-show="$route.meta.title">
                <div class="ml-4 flex items-center md:ml-6">
                    <!-- Profile dropdown -->
                    <Menu as="div" class="relative ml-3">
                        <div>
                            <MenuButton class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                <span class="sr-only">Open user menu</span>
                                <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                            </MenuButton>
                        </div>
                        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                            <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                                <MenuItem v-slot="{ active }">
                                <a href="javascript:void(0)" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']" @click="showmodal = true">Profile
                                </a>
                                </MenuItem>

                                <MenuItem v-slot="{ active }">
                                <a href="" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']" @click="handleNavigation">Logout
                                </a>
                                </MenuItem>

                                <MenuItem v-slot="{ active }">
                                <a href="javascript:void(0)" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']" @click="showmodal2 = true">Change Password
                                </a>
                                </MenuItem>

                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
            </div>
            <profile v-if="showmodal" @close-modal="onProfileModalClose" showmodal="showmodal" />
            <ChangePassword v-if="showmodal2" @close-modal="showmodal2 = false" showmodal="showmodal2" />
        </div>
        <main class="flex-1 bg-[#F1F5F9]">
            <div>
                <slot name="main">

                </slot>
            </div>
        </main>
    </div>
</div>
</template>

<script>
import userApi from '@/utils/userApi';
import profile from "../Auth/profile.vue";
import ChangePassword from "../Auth/changepassword.vue";
import {
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import {
    Bars3BottomLeftIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
    WrenchScrewdriverIcon,
    PhoneIcon,
    GlobeAltIcon,
    TrophyIcon,
    BeakerIcon,
    LightBulbIcon,
    Cog8ToothIcon,
    PuzzlePieceIcon,
    Square3Stack3DIcon,
    PencilSquareIcon,
} from '@heroicons/vue/24/outline'

export default {
    name: "layout-test",
    components: {
        Dialog,
        DialogPanel,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        profile,
        ChangePassword,
        Bars3BottomLeftIcon,
        XMarkIcon,
    },
    data() {
        return {
            userNavigation: [{
                    name: 'Your Profile',
                    href: '#'
                },
                {
                    name: 'Settings',
                    href: '#'
                },
                {
                    name: 'logout',
                    href: '/login'
                },
            ],
            profile: '',
            showmodal: false,
            showmodal2: false,
            sidebarOpen: false,
        }
    },
    computed: {
        navigationItems() {
            return [{
                    name: 'Dashboard',
                    href: '/dashboard',
                    icon: < HomeIcon class = {
                        'text-yellow-500'
                    }
                    />,
                    current: false,
                },
                {
                    name: 'Users',
                    href: '/users',
                    icon: < UsersIcon class = {
                        'text-blue-500'
                    }
                    />,
                    current: false,
                },

                {
                    name: 'Services',
                    href: '/services',
                    icon: < WrenchScrewdriverIcon class = {
                        'text-orange-400'
                    }
                    />,
                    current: false,
                },

                {
                    name: 'Contact US',
                    href: '/contact',
                    icon: < PhoneIcon class = {
                        'text-green-400'
                    }
                    />,
                    current: false,
                },

                {
                    name: 'Skills',
                    href: '/skill',
                    icon: < GlobeAltIcon class = {
                        'text-indigo-400'
                    }
                    />,
                    current: false,
                },
                {
                    name: 'Achievements',
                    href: '/achievement',
                    icon: < TrophyIcon class = {
                        'text-[#D2B48C]'
                    }
                    />,
                    current: false,
                },

                {
                    name: 'Testimonials',
                    href: '/testimonial',
                    icon: < BeakerIcon class = {
                        'text-pink-400'
                    }
                    />,
                    current: false,
                },

                {
                    name: 'Settings',
                    href: '/setting',
                    icon: Cog8ToothIcon,
                    current: false,
                },

                {
                    name: 'Technologies',
                    href: '/technology',
                    icon: < LightBulbIcon class = {
                        'text-sky-400'
                    }
                    />,
                    current: false,
                },

                {
                    name: 'Works',
                    href: '/works',
                    icon: < PuzzlePieceIcon class = {
                        'text-red-500'
                    }
                    />,
                    current: false,
                },

                {
                    name: 'Categories',
                    href: '/categories',
                    icon: < Square3Stack3DIcon class = {
                        'text-yellow-300'
                    }
                    />,
                    current: false,
                },

                {
                    name: 'Posts',
                    href: '/posts',
                    icon: < PencilSquareIcon class = {
                        'text-purple-400'
                    }
                    />,
                    current: false,
                },
            ]

        }
    },
    methods: {
        handleNavigation() {
            return localStorage.clear();
        },
        async onProfileModalClose() {

            this.showmodal = false;
            this.$router.go();
        },
        getProfile() {
            userApi.getprofile().then(response => {
                this.profile = response.data.data.file;
            }).catch(error => {
                console.log(error);
            })
        }

    },
    created() {
        this.getProfile()
    }
}
</script>

<style scoped>
.router-link-active {
    background-color: #cbd5e1;
}
</style>
