import { createWebHistory, createRouter } from "vue-router";
import auth from "./middleware/auth";
import middlewarePipeline from "./middleware/middleware-pipeline";

const routes = [

    {
        path: "/",
        name: "PortfolioPage",
        component: () => import("./components/ProtfolioWeb/Index.vue"),
    },
    {
        path: "/admin",
        name: "Admin",
        meta: {
            middleware: [
                auth
            ]
        },
        redirect: '/dashboard'

    },
    {
        path: "/admin/login",
        name: "Login",
        component: () => import("../src/Auth/Login.vue"),
    },
    {
        path: "/users",
        name: "user-list",
        meta: {
            middleware: [
                auth
            ],
            title:'Users'
        },
        component: () => import("./components/Users/List.vue"),
    },
    {
        path: "/users/edit/:id",
        name: "user-edit",
        meta: {
            middleware: [
                auth
            ],
            title:'Update User'
            
        },
        component: () => import("../src/components/Users/Edit.vue"),
    },  
      {
        path: "/users/view/:id",
        name: "user-view",
        meta: {
            middleware: [
                auth
            ],
            title:'View User'
        },
        component: () => import("../src/components/Users/View.vue"),
    },
    {
        path: "/users/create",
        name: "user-create",
        meta: {
            middleware: [
                auth
            ],
            title:'Create User'
        },
        component: () => import("../src/components/Users/Create.vue"),
    },
    {
        path: "/services",
        name: "service-list",
        meta: {
            middleware: [
                auth
            ],
            title:'Services'

        },
        component: () => import("./components/Services/List.vue"),
    },
    {
        path: "/services/edit/:id",
        name: "service-edit",
        meta: {
            middleware: [
                auth
            ],
            title:'Update Services'
        },
        component: () => import("../src/components/Services/Edit.vue"),
    },
    {
        path: "/services/create",
        name: "service-create",
        meta: {
            middleware: [
                auth
            ],
            title:'Create Services'
        },
        component: () => import("../src/components/Services/Create.vue"),
    },

    {
        path: "/contact",
        name: "contact-list",
        meta: {
            middleware: [
                auth
            ],
            title:'Contact Us'
        },
        component: () => import("./components/ContactUs/List.vue"),
    },
    {
        path: "/contact/create",
        name: "contact-create",
        meta: {
            middleware: [
                auth
            ]
        },
        component: () => import("../src/components/ContactUs/Create.vue"),
    },

    {
        path: "/skill",
        name: "skill-list",
        meta: {
            middleware: [
                auth
            ],
            title:'Skills'
        },
        component: () => import("./components/Skill/List.vue"),
    },
    {
        path: "/skill/edit/:id",
        name: "skill-edit",
        meta: {
            middleware: [
                auth
            ]
        },
        component: () => import("../src/components/Skill/Edit.vue"),
    },
    {
        path: "/skill/create",
        name: "skill-create",
        meta: {
            middleware: [
                auth
            ]
        },
        component: () => import("../src/components/Skill/Create.vue"),
    },

    {
        path: "/achievement",
        name: "achievement-list",
        meta: {
            middleware: [
                auth
            ],
            title:'Achievements'
        },
        component: () => import("../src/components/AchievementPage/List.vue"),
    },
    
    {
        path: "/achievement/edit/:id",
        name: "achievement-edit",
        meta: {
            middleware: [
                auth
            ]
        },
        component: () => import("../src/components/AchievementPage/Edit.vue"),
    },
    {
        path: "/achievement/create",
        name: "achievement-create",
        meta: {
            middleware: [
                auth
            ]
        },
        component: () => import("../src/components/AchievementPage/Create.vue"),
    },

    {
        path: "/testimonial/edit/:id",
        name: "testimonial-edit",
        meta: {
            middleware: [
                auth
            ],
            title:'Update Testimonial'
        },
        component: () => import("../src/components/Testimonial/Edit.vue"),
    },

    {
        path: "/testimonial",
        name: "testimonial-list",
        meta: {
            middleware: [
                auth
            ],
            title:'Testimonials'
        },
        component: () => import("../src/components/Testimonial/List.vue"),
    },
    {
        path: "/testimonial/create",
        name: "testimonial-create",
        meta: {
            middleware: [
                auth
            ],
            title:'New Testimonial'
        },
        component: () => import("../src/components/Testimonial/Create.vue"),
    },

    {
        path: "/setting/edit/:id",
        name: "setting-edit",
        meta: {
            middleware: [
                auth
            ]
        },
        component: () => import("../src/components/Setting/Edit.vue"),
    },

    {
        path: "/setting",
        name: "setting-list",
        meta: {
            middleware: [
                auth
            ],
            title:'Settings'
        },
        component: () => import("../src/components/Setting/List.vue"),
    },
    {
        path: "/setting/create",
        name: "setting-create",
        meta: {
            middleware: [
                auth
            ]
        },
        component: () => import("../src/components/Setting/Create.vue"),
    },

    
    {
        path: "/technology",
        name: "technology-list",
        meta: {
            middleware: [
                auth
            ],
            title:'Technologies'
        },
        component: () => import("../src/components/Technology/List.vue"),
    },

    {
        path: "/technology/edit/:id",
        name: "technology-edit",
        meta: {
            middleware: [
                auth
            ]
        },
        component: () => import("../src/components/Technology/Edit.vue"),
    },

    {
        path: "/technology/create",
        name: "technology-create",
        meta: {
            middleware: [
                auth
            ]
        },
        component: () => import("../src/components/Technology/Create.vue"),
    },

    {
        path: "/works/edit/:id",
        name: "work-edit",
        meta: {
            middleware: [
                auth
            ],
            title:'Update Work'
        },
        component: () => import("../src/components/Works/Edit.vue"),
    },

    {
        path: "/works",
        name: "work-list",
        meta: {
            middleware: [
                auth
            ],
            title:'Works'
        },
        component: () => import("../src/components/Works/List.vue"),
    },
    {
        path: "/works/create",
        name: "work-create",
        meta: {
            middleware: [
                auth
            ],
            title:'New Work'
        },
        component: () => import("../src/components/Works/Create.vue"),
    },

    {
        path: "/categories/edit/:id",
        name: "categories-edit",
        meta: {
            middleware: [
                auth
            ]
        },
        component: () => import("../src/components/Categories/Edit.vue"),
    },

    {
        path: "/categories",
        name: "categories-list",
        meta: {
            middleware: [
                auth
            ],
            title:'Categories'
        },
        component: () => import("../src/components/Categories/List.vue"),
    },
    {
        path: "/categories/create",
        name: "categories-create",
        meta: {
            middleware: [
                auth
            ]
        },
        component: () => import("../src/components/Categories/Create.vue"),
    },

    {
        path: "/posts/edit/:id",
        name: "posts-edit",
        meta: {
            middleware: [
                auth
            ],
            title:'Update Post'
        },
        component: () => import("../src/components/Post/Edit.vue"),
    },

    {
        path: "/posts",
        name: "post-list",
        meta: {
            middleware: [
                auth
            ],
            title:'Posts'
        },
        component: () => import("../src/components/Post/List.vue"),
    },
    {
        path: "/posts/create",
        name: "posts-create",
        meta: {
            middleware: [
                auth
            ],
            title:'New Post'
        },
        component: () => import("../src/components/Post/Create.vue"),
    },

    {
        path: '/profile',
        name: 'profile',
        component: () =>
            import('../src/Auth/profile.vue'),
    },

    {
        path: '/changepassword',
        name: 'changepassword',
        component: () =>
            import('../src/Auth/changepassword.vue'),
    },

    {
        path: "/dashboard",
        name: "Dashboard",
        meta: {
            middleware: [
                auth
            ],
            title:'Dashboard'
        },
        component: () => import("../src/components/Dashboard/Index.vue"),
    },
    {
        path: '/:pathMatch(.*)*',
        name: "404",
        component: () => import("../src/PageNotFound.vue"),
    },


]
const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass :'menu-active-link',
})

router.beforeEach((to, from, next) => {

    /** Navigate to next if middleware is not applied */
    if (!to.meta.middleware) {
        return next()

    }

    const middleware = to.meta.middleware;
    const context = {

        to,
        from,
        next,
        //   store  | You can also pass store as an argument
    }

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})



export default router;