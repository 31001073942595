<template>
<!-- <div v-if="isOpen"> -->
<section class="z-20 h-screen w-screen bg-gray-500 fixed top-0 opacity-50" @click="$emit('close-modal')"></section>
<div class="absolute inset-0">
    <div class="flex mt-[3.75rem]">
        <div class="z-30 m-auto bg-white border rounded p-2 shadow md:w-1/3 w-4/5">
            <div class="border p-3">
                <h1 class="text-2xl text-center font-medium">Profile</h1>
                <form @click="prevent" class="">
                    <div class="my-1">
                        <label>Name</label>
                        <input v-model="fname" class="rounded w-full p-2 border" type="text" placeholder="Name">
                    </div>
                    <div>
                        <label class="text-sm text-gray-700 block mb-1 font-medium">files</label>
                        <input class="bg-gray-100 border border-gray-200 rounded py-1 px-3 block focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full" type="file" @change="addFile" />
                    </div>
                    <div id="preview" class="w-20 h-20 rounded-full">
                        <img v-if="url" :src="url" />
                    </div>
                    <div class=" flex justify-end gap-4">
                        <button id="portfolio" type="button" class="px-3 py-2 text-white" @click="updateProfile">Save</button>
                        <button @click="$emit('close-modal')" type="button" class="px-3 py-2 bg-black text-white">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- </div> -->
</template>

<script>
import userApi from '@/utils/userApi';
export default {
    props: {
        showmodal: Boolean,
    },
    data() {
        return {
            fname: '',
            file: '',
            url: null
        }
    },
    methods: {
        async updateProfile() {
            let params = {
                fname: this.fname,
                file: this.file
            }
            await userApi.updateprofile(params)
            // this.$swal({
            //         toast: true,
            //         position: 'top-end',
            //         icon: 'success',
            //         title: 'Your work has been saved',
            //         showConfirmButton: false,
            //         timer: 1500
            //     })
                .then((res) => {
                    localStorage.setItem("user", JSON.stringify(res.data.data));
                }).catch(error => {
                    console.log(error);
                })

            this.$emit('close-modal');
        },
        addFile: function (evt) {
            const file = evt.target.files[0]
            this.url = URL.createObjectURL(file);
            this.file = file

        }

    },
    created() {
        this.fname = JSON.parse(localStorage.getItem('user')).fname;
    }
}
</script>
