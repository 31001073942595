import axios from 'axios';
import authHeader from './setAuthHeader';
let API_URL = process.env.VUE_APP_ROOT_API


class usersApi {
  create(data) {
    return axios.post(API_URL + 'admin/users/store', data, { headers: authHeader() });
  }

  index(page, perPage, data = '') {
    return axios.get(API_URL + `admin/users?page=${page}&per_page=${perPage}&search=${data}`, { headers: authHeader() });
  }

  getuser(data = '') {
    return axios.get(API_URL + `admin/users/list?search=${data}`, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(API_URL + `admin/users/${id}` + "/delete", { headers: authHeader() });
  }
  update(id, data) {
    return axios.post(API_URL + `admin/users/${id}` + "/update", data, { headers: authHeader() });
  }
  get(id) {
    return axios.get(API_URL + `admin/users/${id}` + "/show", { headers: authHeader() });
  }
  changeStatus(id, data) {

    return axios.post(API_URL + `admin/users/${id}` + "/status/update", data, { headers: authHeader() });
  }

  updateprofile(data) {
    return axios.post(API_URL + 'admin/profile/update', data, { headers: authHeader() });
}
getprofile(){
  return axios.get(API_URL + 'admin/profile/show', { headers: authHeader() });
}

}

export default new usersApi();