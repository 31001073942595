import { createStore } from 'vuex';
import _ from 'lodash';

export default createStore({
    state: {
        user: '',
    },
    mutations: {
        loginUser(state, user) {
            state.user = user;
            localStorage.setItem("token", user.token);
            localStorage.setItem("user", JSON.stringify(user));
        },
    },
    actions: {

    }

})